<template>
    <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
        <a href="#" class="flex w-full items-center justify-center gap-3 rounded-md bg-white hover:scale-125 hover:ease-in-out hover:duration-300 shadow-lg py-4 px-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#000000]">
          <svg class="h-6 w-6" aria-hidden="true" viewBox="0 0 20 20">
            <image xlink:href="/assets/Google_logo.webp" width="20" height="20" />
          </svg>
        </a>

        <a href="#" class="flex w-full items-center justify-center gap-3 rounded-md   bg-white hover:scale-125 hover:ease-in-out hover:duration-300 shadow-lg py-4 px-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#000000]">
          <svg class="h-6 w-6" aria-hidden="true" viewBox="0 0 20 20">
            <image xlink:href="/assets/Facebook_logo.webp" width="20" height="20" />
          </svg>
        </a>

        <a href="#" class="flex w-full items-center justify-center gap-3 rounded-md bg-white hover:scale-125 hover:ease-in-out hover:duration-300 shadow-lg py-4 px-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#000000]">
          <svg class="h-6 w-6" aria-hidden="true" viewBox="0 0 20 20">
            <image xlink:href="/assets/Apple_logo.webp" width="20" height="20" />
          </svg>
        </a>
    </div>
</template>